import React from 'react'
import { useRouter } from 'next/router'
import Image from 'next/image';
import Head from "next/head";

function Index() {
  const router = useRouter();
  return (
    <div className='min-h-screen flex flex-col justify-center pb-6 items-center  max-w-xl mx-auto text-center px-5'>
        <Head>
         <title>{'ASSESSMENT'}</title>
            </Head>
      <Image 
        src='/images/logo-lissun.svg'
        width={165}
        height={85}
      />
      <div className='-mt-8'>
        <Image 
          src='/images/landing.svg'
          width={400}
          height={400}
        />
        <h4 className='font-Nunito font-normal text-lg md:text-2xl text-grey-800 -mt-5 md:-mt-10 mb-1'>Are you ready? Let’s check your score!</h4>
        <p className='text-sm md:text-base text-grey-800'>Designed by our team of clinical psychologists,  You <br /> would also come to know the mildness or severity <br /> of it if present!</p>
        <button className='bg-lightSpray rounded-full py-3.5 px-10 w-full max-w-xs mx-auto mt-10 font-semibold text-base' onClick={() => router.push('assessments/')}>Get Started</button>
      </div>
    </div>  
  )
}

export default Index